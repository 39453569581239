body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden !important ;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.weight-500{
  font-weight: 500!important;
}
.black{
  color: #242424 !important;
}
.form-padding{
  padding-right: 8px !important;
}
.loader{
  position: relative;
  width: 100vw;
  height: 100vh;
  /* background-color: skyblue!important; */
}
/* .bgRed{
  background-color: wheat !important;
} */
.weight-900{
  font-weight:750 !important;
}
.loader div{
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-30%,-30%);
}
.front-banner{
  /* display: flex;
  justify-content: center;
  align-items: center; */
  height: 100vh;
  width: 100vw;
  /* background-image: url('./banner.jpg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* position: relative; */
}
.front-banner::before{
  content: "";
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.4);
  box-shadow: inset 4.375rem 3.125rem 11.875rem #000, inset -4.375rem -3.125rem -11.875rem;
  z-index: 1;
  height: 100%;
}

/* .front-banner::after{
  content: "";
  position: absolute;
  top: 5.4rem;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.4);
  box-shadow: inset 4.375rem 3.125rem 11.875rem #000, inset -4.375rem -3.125rem -11.875rem
} */
#nav{
  position: relative !important;
  z-index: 1 !important;
}
#montserrat{
  font-family: 'Montserrat' !important;
}
.newdiv{
  display: flex !important;
  flex-direction: column;
  justify-content: space-around!important;
  z-index: 1 !important;
  width: 14rem;
  height: 45rem;
}
#sub-heading{
  position: absolute;
  top: 55%;
  left: 2%;
  color:white;
  z-index: 1;
  width: 30rem;
  font-weight: 600 !important;
  font-size: large;
}
#ideas{
  width: 60vw ;
  font-size: 5rem;
  color:white;
}
#top-line{
  width: 200px;
  height: 8px;
  /* background-color: red; */
  position: absolute;
  top: 62%;
  left: 10%;
}
.underline-main{
  position: relative;
}
.color-underline{
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  height: 5px;
  width: 100px;
}
.main-img a {
  position: absolute;
  top: 80%;
  left: 10%;
  z-index: 1;
  width: 5rem !important;
}
/* mobile */
@media screen and (max-width:500px){
  .front-banner{
    height: 25rem;
    position: relative;
  }
 #ideas{
  width: 25rem !important;
  padding-right: 0.5rem;
  font-weight: 400 !important;
  font-size: 3.3rem !important;
  position: absolute;
  color: white;
  z-index: 1;
  top: 13% !important;
  left:0% !important;
  font-family: sans-serif !important;
 }
 #sub-heading{
  position: absolute;
  top: 25% !important;
  left: 4% !important;
  color: white;
  z-index: 1;
  width: 22rem !important;
  font-weight: 400;
  padding-top: 0 !important;
}
#top-line{
  width: 150px;
  height: 3px !important;
  background-color: red;
  position: absolute;
  top: 35% !important;
  left: 5% !important;
}
.main-img a {
  position: absolute;
  top: 75% !important;
  left: 0% !important;
  z-index: 1;
}
.mobile-text{
  font-size: 12px !important;
}
}
/* tablet */
@media screen and (max-width:850px){
  .front-banner{
    height: 25rem;
    position: relative;
  }
  /* .front-banner::after{
    top: 0% !important;
  } */
  #ideas{
    width: 70vw;
    font-weight: 400 !important;
    font-size: 3.5rem;
    position: absolute;
    color: white;
    z-index: 1;
    top: 7% !important;
    left:2.5% !important;
    font-family: sans-serif !important;
   }
  .main-img h1{
    font-weight: 500;
    font-size: 5rem !important;
    position: absolute;
    z-index: 1;
    top: 10% !important;
    left:6% !important;
    font-family: sans-serif;
  }
  #sub-heading{
    position: absolute;
    top: 30%;
    left: 2.5%;
    color: white;
    z-index: 1;
    width: 30rem;
    font-weight: 400;
    padding-top: 0.5rem;
  }
  #top-line{
    width: 150px;
    height: 6px;
    background-color: red;
    position: absolute;
    top: 50%;
    left: 6%;
  }
  .main-img a {
    position: absolute;
    top: 82%;
    left: 0% !important;
    z-index: 1;
  }
}
/*small phone */
@media screen and (max-width:350px){
  #ideas{
    width:  100vw !important;
    font-size: 2.8rem !important;
    left: 6% !important;
  }
  .front-banner{
    height: 65vh;

  }
  #sub-heading{
    font-size: 15px;
    left: 7% !important;
    top: 25% !important;
    width: 20rem !important;
  }
  .main-img a {
    position: absolute;
    top: 20% !important;
    left: 2% !important;
    z-index: 1;
    width: 3rem !important;
  }
  .newdiv{
    height: 40rem !important;
  }
}
.mobile-color{
  color: blue;
}
.Clock {
  padding: 5px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}
.nav-link-phn{
  background-color: #fafafa !important;
}
.nav-link-text{
  color: black !important;
}
.main-img{
  z-index: 1;
}
.main-img h1{
  font-weight: 700;
  font-size: 8rem;
  position: absolute;
  color: white;
  z-index: 1;
  top: 30%;
  left:10%;
  font-family: sans-serif;
}

@media screen and (min-width:1000px){
  .front-banner{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* page animation */
.dialog-enter {
  opacity: 0.01;
 
  transform: translateY(-50%);
}

.dialog-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 300ms;
}

.dialog-exit {
  opacity: 1;
 transform: scale(1);
  transform: translateY(-50%);
}

.dialog-exit-active {
  opacity: 0.01;

  transform: translateY(-50%);
  transition: all 300ms;
}